@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }  
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }  
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str} 1.25s ease infinite;      
}

@include keyframes(loading-container) {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

//  Container
.loading-container {
  display: flex;
  justify-content: center;
}

//  Rotating border
.loading-container::after {
  content: "";
  width: 100%;
  height: 100%;
  background: transparent;
  border: 5px solid transparent;
  border-top-color: #B31983;
  border-radius: 50%;
  @include animation('loading-container');
}

.loading-indicator-logo {
  display: block;
  height: inherit;
  width: inherit;
  position: absolute;
}

svg {
  position: absolute;
}

.loading-indicator-position-container {
  position: relative;
}

.loading-indicator-background{
  content: "";
  background-color: rgba(128, 128, 128, .25) ;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}