.holidays-table {
  table {
    width: 100%;
  }

  th, td {
    width: 60%;

    &:first-child, &:last-child {
      width: 15%;
    }

  }

  tr {
    display: flex;
    justify-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #000;
    align-items: baseline;
  }
}

.calendar-name {
  margin-top: 1em;
}
