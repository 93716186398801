.error {
  color: #b30000;
  font-size: 14px;
}

.modal {
  margin-top: 70px !important;
  max-width: 1140px !important;
}

.modalHeader {
  padding-bottom: 16px !important;
}

.modalBody {
  padding: 36px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  height: 46vh !important;
}

.subText {
  width: 30em;
  font-size: 14px;
}

.vanityButton {
  height: calc(1.5em + 0.75rem + 2px);
  float: left;
  margin: 1em;
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}
