@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }  
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }  
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str} 1.25s ease infinite;      
}

@include keyframes(updating-container) {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

//  Container
.updating-container {
  display: flex;
  justify-content: center;
}

//  Rotating border
.updating-container::after {
  content: "";
  width: 100%;
  height: 100%;
  background: transparent;
  border: 2px solid transparent;
  border-top-color: #B31983;
  border-radius: 50%;
  @include animation('updating-container');
}

.updating-indicator-logo {
  display: block;
  height: inherit;
  width: inherit;
  position: absolute;
}

svg {
  position: absolute;
}

.updating-indicator-position-container {
  position: relative;
  display: flex;
  justify-content: center;
}
