.cms-site-testing-jobs-container{
    background-color: white;
    margin: 10px;
    padding: 30px;
    border-radius: 10px;
}

.cms-site-testing-jobs-content-container {
    border: none;
}

.cms-site-testing-jobs-table-container {
    display: flex;
    justify-content: center!important;
    height: 90%;
    margin-top: 25px;
}

.cms-site-testing-jobs-table-heading {
    font-weight: bold;
}

tbody tr {
    cursor: pointer;
}

.create-button {
    float: right;
}

.max-progress-width {
    max-width: 100%;
}

.progress {
    background-color: #97dfa8!important;
}

.btn-primary-override {
    background-color: #97dfa8!important;
    border-color: #97dfa8!important;
}

.table thead th {
  vertical-align: top!important;
  padding: 0.25rem;
  height: 44.5px;
  min-width: 120px!important;
}

.subscriptionTitle {
  color: #fff;
  font-weight: bold;
}

.subscriptionName {
  color: #fff;
}

.progress-heading-text {
  display: block;
  float: left;
}

.progress-heading-indicator {
  display: block;
  float: right;
}