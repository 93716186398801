#WebhostingPage {
  display: flex;
  justify-content: left;
}

.subscriber-lookup-form {
  .col-form-label {
    font-size: 0.875rem;
    font-weight: bold;
  }
}

.pane {
  box-shadow: 1px 1px 5px 1px #0000005e;
  background-color: #fff;
  height: calc(100% - 60px);
  padding: 20px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  width: 100%;
}

.subscriber-lookup-input {
  width: 500px !important;
  flex: none !important;
}

.subscriber-lookup-button {
  margin-left: 10px;
}

.search-result-block {
  margin-top: 20px;
}

.products-table-block {
  margin-top: 20px;
}
