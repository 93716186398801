$spinnerSize: 5rem;

.ajaxSpinner {
  .spinnerWrap {
    top: 50%;
    left: 50%;
    z-index: 2001;
    margin-left: -$spinnerSize / 2 - 2.2rem;
    margin-top: -$spinnerSize / 2;
    position: fixed;
    .spinner {
      width: $spinnerSize;
      height: $spinnerSize;
      margin-left: 2.2rem;
      border-width: 0.5rem;
    }
    .text {
      font-size: 1rem;
      margin-top: 1rem;
    }
  }
  .background {
    background-color: #000;
    opacity: 0.8;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 60px;
    left: 0;
    z-index: 2000;
  }
}
