@import './variables.scss';
@import './pages/pages.scss';

#RootPage {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

unison-div[class^="index_ve-app-switcher"]{
  float: left;
}
