.header-title {
  font-size: 28px;
  font-weight: bold;
}

.customer-name {
  display: block;
  font-weight: bold;
  margin-left: 1%;
  font-size: 20px;
}

.vanity-button {
  margin: 1em;
  &.vanity-button-inactive {
    cursor: auto!important;
    &:focus {
      box-shadow: none!important;
    }
  }
}

#space-up {
  padding-top: 50px;
}

#space-up-left {
  padding-left: 20px;
  padding-top: 50px;
}

#space-left {
  padding-left: 20px;
}

#space-down {
  padding-bottom: 30px;
  vertical-align: baseline;
}

#space-down-left {
  padding-bottom: 30px;
  padding-left: 20px
}

.details {
  margin-top: 30px;
  width: 98%;
  margin-left: 1%;
}

td {
  padding: 5px 0
}

td.bold-title {
  font-weight: bold;
}

.lined {
  border-top: 1px solid #ddd;
}

.bold-capitalize {
  text-transform: capitalize;
  font-weight: bold;
}

.title-area{
  display: flex;
  padding: 0 15px;
  justify-content: space-between;
}
.close-button {
  border: none;
  background-color: white;
  font-weight: bold;
  font-size: 20px;
  color: gray;
  &:focus {
    outline: none;
  }
}
