.page-container {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.sites-pane {
  box-shadow: 1px 1px 5px 1px #0000005e;
  background-color: #fff;
  margin-left: 5px !important;
  margin-right: 5px !important;
  padding-top: 20px;
  width: 100%;
}
